import * as PageTypes from 'constants/constants';

import Head from 'next/head';

import { ComponentSelector } from '@components/ComponentSelector/ComponentSelector';
import { SkeletonSelector } from '@components/SkeletonSelector/SkeletonSelector';

import { getConfig } from '@utils/config.utils';

import tealium from '@services/Tealium.service';
import { getDeviceType } from '@utils/deviceType.utils';

import {
  getContentfulService,
  useContentContext,
  ContentHolder,
  getRouteData,
  setInitialApplicationValues,
  getPageData,
  attachDomaintoPath,
  getAppRoute,
  getVotingRoute,
  setPageType
} from '../src/imports';

import applicationData from '../application_data.json';

const Pages = ({
  data,
  pageType,
  type,
  url,
  brand,
  categoryArticles,
  deviceType,
  appRoute,
  votingRoute
}: any) => {
  const config = getConfig();
  const { state } = useContentContext();
  const path = attachDomaintoPath(url);

  return (
    <div>
      <Head>
        {state.title ? (
          <title key="title">
            {state.title} | {brand}
          </title>
        ) : (
          ''
        )}
        <link
          key="canonical"
          rel="canonical"
          href={process.browser ? window?.location?.href : path}
          data-react-helmet
        />
      </Head>
      <ContentHolder
        data={{
          data,
          type: pageType,
          config,
          fullPath: url,
          categoryArticles,
          appRoute,
          votingRoute
        }}
        deviceType={deviceType}
        componentSelector={ComponentSelector}
        skeletonSelector={SkeletonSelector}
        tealium={tealium}
      />
    </div>
  );
};
export default Pages;

export async function getServerSideProps(context: any) {
  const imageLambdaBase = process.env.NEXT_PUBLIC_IMAGELAMBDA;
  const queryParams = context.query;
  const allSlugs = context.params.slug;
  const { resolvedUrl } = context;
  if (
    resolvedUrl === '/sw-pwa.js' ||
    resolvedUrl === '/assets/css/react-carousel.es.css.map' ||
    resolvedUrl === '/player'
  ) {
    return {
      props: {}
    };
  }
  let slugArray = [...allSlugs];
  if (slugArray?.[0] === 'app' || slugArray?.[0] === 'voting') {
    slugArray.shift();
  }

  let slug = slugArray.join('/');

  const application: any = applicationData.data.application;
  const content = getContentfulService();
  const config = getConfig();
  const deviceType = getDeviceType(context);
  let routeData: any;
  let pageType: any;

  if (application.numberOfLatestArticles)
    content.setLatest(application.numberOfLatestArticles);

  const { brandName, articleType, brandLogoUrl, fallbackImage, homepageSlug } =
    setInitialApplicationValues(application);

  const isSearchPage =
    allSlugs.slice(-1)?.pop().toLowerCase() === PageTypes.SEARCH.toLowerCase();

  if (isSearchPage) {
    pageType = PageTypes.SEARCH;
  } else {
    routeData = await getRouteData(allSlugs, resolvedUrl, config);
    pageType = routeData.pageType;
  }

  const appRoute = getAppRoute();
  const votingRoute = getVotingRoute();

  pageType = setPageType(routeData, slug, pageType);

  const { mapData, metadata, schemaData, notFound } = await getPageData(
    pageType,
    context,
    routeData,
    brandName,
    brandLogoUrl,
    fallbackImage,
    homepageSlug,
    config,
    articleType,
    appRoute,
    votingRoute
  );
  if (notFound) {
    return {
      notFound: true
    };
  }

  if (pageType === PageTypes.SEARCH) {
    return {
      props: {
        pageType: PageTypes.SEARCH,
        brandName,
        metadata,
        schemaData,
        url: 'search'
      }
    };
  }

  if (pageType === PageTypes.REDIRECTION) {
    return {
      props: {}
    };
  }

  return {
    props: {
      ...mapData,
      brand: brandName,
      deviceType,
      queryParams,
      imageLambdaBase,
      metadata,
      schemaData,
      appRoute,
      votingRoute
    }
  };
}
